.login-signin {
    align-items:center;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    /* height: 812px; */
    /* padding: 0px 58.7px;
    width: 390px; */
}

.first-time-login {
    align-items: center;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    width: 260px;
}

