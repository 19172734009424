.slick-dots li.slick-active button:before {
  color: #6352e8;
}

.slick-prev:before,
.slick-next:before {
  color: #6352e8;
}
.slick-prev:before,
.slick-next:before {
   display:none;
}
/* .slick-slider{
  
  
  
} */
/* .slick-list{
  border:1px solid red;
  width :730px;
} */
.AvatarModal{
  background: none !important;
  border: none !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 15% 0px 0px 0px !important;
}

.AvatarModalTop{
  margin: 40% 0px 0px 0px !important;
}

.card-container{ 
  max-width: 250px !important; 
  min-height: 330px !important;    
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.flipped > .front {
      transform: rotateY(180deg);
                    }
.flipped > .back {
    transform: rotateY(0deg);
      }
.front{    
    display: block;        
    position: absolute;   
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
  }
.back {        
    display: block;        
    position: absolute;   
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
  }
  .front {
    width: 100%;
      z-index: 2;
      transform: rotateY(0deg);              
    }
.back {
  width: 100%;
    transform: rotateY(-180deg);   
    font-size: 20px;
}
.card-body{
  width: 100%;
  display: flex;
  justify-content: center;
}
.HomeshareBox{
    padding-top:1em;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 12rem;
    /* height: 5rem; */
    flex-shrink: 0;
    background-image: url("../assets/images/tooltip.svg"); 
    background-size: 193px 146px;    
background-repeat: no-repeat;
    /* background-color: white; */
}

@media screen and (max-width: 450px) {
  .card-container{ 
    max-width: 310px;
  }
}

.buttonHover:hover {
  background-color: #6352e8; 
}

.buttonHover:hover a {
  color: #ffffff;
}