.newtooltip{
    background: var(--color-160133) 0 0% no-repeat padding-box;
      border: 2px solid var(--color-6352e8);
      box-shadow: 0 3px 6px #00000029;
      border-radius: 0 20px 20px 20px;
      opacity: 1;
      font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-13) var(--font-family-poppins);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-ffffff);
      text-align: center;
      /* width: 300px; */
      padding: 15px !important;  
      position: absolute;
      z-index: 10;
  }
  .pay-custom-select-container {
    display: inline-block;
    width: 100%;  
    /* text-align: center; */
    
    position: relative;
  }
  
  .pay-selected-textv2 {
    /* background-color: #47eded; */
    background-color: transparent;
    border: 1px solid #cab7ff;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 8px 8px 8px 8px;
    
    /* border-bottom: 1px solid #37b0b0; */
  }
  .pay-selected-textv2.active::after {
    top: 8px;  
    border-color: transparent transparent #fff transparent;
  }
  .pay-selected-textv2.active {
    border-radius: 8px 8px 0px 0px;
    border-bottom:none ;
  }
  
  .pay-selected-text {
    /* background-color: #47eded; */
    background-color: transparent;
    border: 1px solid #cab7ff;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 8px 8px 8px 8px;
    
    /* border-bottom: 1px solid #37b0b0; */
  }
  
  .pay-selected-text::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 20px;
    border: 7px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  
  .pay-selected-text.active::after {
    top: 8px;  
    border-color: transparent transparent #fff transparent;
  }
  .pay-selected-text.active {
    border-radius: 8px 8px 0px 0px;
    border-bottom:none ;
  }
  
  
  .pay-custom-select-container ul {
    /* border: 1px solid #cab7ff; */
    /* border-radius: 0px 0px 8px 8px ;
    border-top: none; */
    margin: 0;
    padding: 0 0px;
    text-align: center;
  }
  
  .pay-select-options {
    position: absolute;
    width: 100%;    
    overflow: scroll
  }
  
  .pay-custom-select-container ul li {
    list-style-type: none;
    padding: 10px 20px;
    /* background: transparent; */
    background: #1e0243;
    border-top: 1px solid #cab7ff;  
    text-align: left;
    font-size: 15px;
    cursor: pointer;
  }