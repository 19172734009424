.textsomechange span{
    font-size: 1.2em;
    color:#ffd02c;
    font-weight: 700;
}

.description{
    color: #FFF;
    font-family: Poppins;
    font-style: normal;

    
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
}

.footerAmb{
    color: #e6d348;
letter-spacing:2px;

  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  background: -webkit-linear-gradient(180deg, rgba(243,236,60,1) 0%, rgba(212,176,92,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  /* text-transform: uppercase;   */
  text-shadow: 0px 1px 3px 0px #5B03FF;
  font-family: 'Lilita One';
  text-align: center;
  font-size: 1.2rem;
}
.foreverText{
    color: #FEFEFE;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 725;
    line-height: 2.1875rem;
    letter-spacing: 0.02375rem;
    
}

.shareBox{
    padding-top:1em;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 15.9rem;
    /* height: 5rem; */
    flex-shrink: 0;
    background-image: url("../../assets/images/tooltip.svg");      
background-repeat: no-repeat;
background-size: 246px 160px;
    /* background-color: white; */
}
.shareBox2{
    padding-top:1em;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 15.9rem;
    
    /* height: 10rem;  */
    flex-shrink: 0;
    background-image: url("../../assets/images/tooltip.svg");      
background-repeat: no-repeat;
background-size: 246px 160px;
    /* background-color: white; */
}
.shareBox img,span{
    cursor:pointer;
}
.shareSubHeaderText{
   
    background-clip: text;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.0275rem;
}